<template>
  <div
    id="alert-container"
    :class="variant"
  >
    <span @click="() => updateActive(false)"> close </span>
    <div id="message-container">
      {{ message }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import useAlertBannerStore from "@/store/AlertBanner";

const { updateActive } = useAlertBannerStore();

defineProps({
  message: {
    type: String,
    required: true,
  },
  variant: {
    type: String,
    required: false,
    default: "success",
  },
});
</script>

<style scoped lang="scss">
@import './../assets/scss/index.scss';

#alert-container {
  width: calc(100% - 30px);
  height: 80px;
  padding: 15px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;

  span {
    position: absolute;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  #message-container {
    width: 100%;
    text-align: center;
  }

  &.success {
    background: $pine-green;
    background: #269160;
    color: white;

    span {
      color: white;
    }
  }

  &.warning {
    background: $tangerine-yellow;
  }

  &.danger {
    background: $blood-red;
  }
}
</style>