export type AnalyticsEvent = {
  event: string;
  page?: string;
};

declare const dataLayer: AnalyticsEvent[];

class Analytics {
  private isGA4Ready: boolean = false;
  private queuedEvents: { eventName: string, params: Record<string, unknown> }[] = [];

  constructor() {
    this.checkGA4Ready();
  }

  private checkGA4Ready() {
    if (typeof window.gtag === 'function') {
      this.isGA4Ready = true;
      this.queuedEvents.forEach(({ eventName, params }) => this.event(eventName, params));
      this.queuedEvents = []; 
    } else {
      setTimeout(() => this.checkGA4Ready(), 100);
    }
  }

  pageView(page: string, title: string) {
    const params = {
      page_path: page,
      page_title: title,
    };
    this.event('page_view', params);
  }

  event(eventName: string, params: Record<string, unknown>) {
    if (this.isGA4Ready && typeof window.gtag === 'function') {
      window.gtag('event', eventName, params);
    } else {
      console.warn('Google Analytics (gtag) is not initialized, queuing event:', eventName);
      this.queuedEvents.push({ eventName, params });
    }
  }
}

export default new Analytics();
