import { hosts } from "@/constants/Hosts";

export default function setupGoogleAnalytics() {
  let ga4AccountId = "";
  if (hosts.prod === window.location.hostname) {
    console.log("GA4 Prod Mode");
    ga4AccountId = "G-X34SJKQBRJ";
  } else if (hosts.staging === window.location.hostname) {
    console.log("GA4 Staging Mode");
    ga4AccountId = "G-89KGRF7R3J";
  } else if (
    hosts.dev === window.location.hostname ||
    hosts.local === window.location.hostname
  ) {
    console.log("GA4 Dev Mode");
    ga4AccountId = "G-9TM4SV42RY";
  }

  const ga4Script = document.createElement("script");
  ga4Script.setAttribute("async", "");
  ga4Script.setAttribute(
    "src",
    "https://www.googletagmanager.com/gtag/js?id=" + ga4AccountId
  );
  document.head.appendChild(ga4Script);

  ga4Script.onload = () => {
    console.log('GA4 Script Loaded');
    const ga4AttributeScript = document.createElement("script");
    const ga4AttributeScriptBody = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${ga4AccountId}', { 
            custom_map: { dimension1: 'tenant', dimension2: 'uptaker' }
          });
        `;
  
    ga4AttributeScript.innerText = ga4AttributeScriptBody;
    document.head.appendChild(ga4AttributeScript);
  };
}
