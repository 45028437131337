<template>
  <div id="customer-code-container">
    <p><b>Enter your customer code</b></p>
    <input
      v-model="customerCode"
      aria-label="Customer Code"
      type="text"
      style="text-align: center"
      autofocus
      @input="customerCodeValidation"
      @keyup.enter="submitCustomerCode"
    >
    <button @click="submitCustomerCode">
      Proceed to Login
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import useAlertBannerStore from "@/store/AlertBanner";
import { useAPI } from "@/api";
import Analytics from '@/api/analytics';

const { updateActive, updateMessage, updateVariant } = useAlertBannerStore();
const customerCode = ref<string | null>(null);
const API  = useAPI();

const emit = defineEmits(["customer-code"]);

function customerCodeValidation(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value.toLowerCase();

  if (value.startsWith('-')) {
    updateVariant("warning");
    updateMessage("Dash '-' is not permitted as the first character");
    updateActive(true);
    value = value.substring(1);
  }

  if (value.includes('--')) {
    updateVariant("warning");
    updateMessage("Double dash '--' is not permitted");
    updateActive(true);
    value = value.replace(/--+/g, '-');
  }

  if (value.length > 36) {
    updateVariant("warning");
    updateMessage("Only 36 characters are allowed");
    updateActive(true);
    value = value.slice(0, 36);
  }

  customerCode.value = value;
}

async function submitCustomerCode() {
  if (!customerCode.value) {
    updateVariant("warning");
    updateMessage("Empty code");
    updateActive(true);
    return;
  }
  else if (customerCode.value.endsWith('-')) {
    updateVariant("warning");
    updateMessage("Dash '-' is not permitted as the last character");
    updateActive(true);
    customerCode.value = customerCode.value.slice(0, -1);
    return;
  }

  try {
    const res_status = await API.CustomerCode.checkCustomerCode(customerCode.value);
    if (res_status == 200) {
      emit("customer-code", customerCode.value);
      return;
    }
  } catch (error: any) {
    updateVariant("warning");
    updateMessage("Invalid code");
    updateActive(true);
    customerCode.value = "";
    return;
  }
}

onMounted(() => {
  Analytics.pageView('redirect_uri', "Customer Code Screen");
})

</script>

<style scoped lang="scss">
@import "./../assets/scss/index.scss";

#customer-code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: $margin-s;
  }

  input {
    margin-bottom: $margin-m;
  }
}
</style>