import axios, { type InternalAxiosRequestConfig, type AxiosError, type AxiosInstance, type AxiosResponse } from 'axios';

class Client {
  public instance: AxiosInstance;

  public baseUrl: string;

  public isRefreshing = false;

  public constructor(baseUrl = '') {
    this.instance = axios.create();
    this.baseUrl = baseUrl;
    this.initializeResponseInterceptor();
    this.initializeRequestInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this.handleResponse, this.handleError);
  };

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError);
  };

  private handleRequest = async (config: InternalAxiosRequestConfig) => {
    try {
      config.baseURL = this.baseUrl;
      if(config.headers) {
        config.headers.Accept = 'application/json, text/plain';
      }
      config.withCredentials = false;
      return config;
    } catch (error: any) {
      throw new Error(`${(error.message, error.config)}`);
    }
  };

  public handleResponse = (response: AxiosResponse) => response;

  public handleError = (error: AxiosError) => {
    this.handleBadRequest(error);
    return Promise.reject(error);
  };

  public handleBadRequest = (error: AxiosError) => {
    return Promise.reject(error);
  };
}

export default Client;
