import Client from "../client";
import { CoralogixRum } from '@coralogix/browser';

export default class CustomerCodeApi extends Client {
  constructor(baseUrl = import.meta.env.VITE_CUSTOMER_CODE_URL) {
    super(baseUrl);
    this.baseUrl = baseUrl;
  }

  public async checkCustomerCode(code: string) {
    const response = await this.instance.get("/auth/realms/" + code);

    CoralogixRum.setLabels({
      ...CoralogixRum.getLabels(),
      tenant_id: code
    });

    return response.status;
  }
  
  // currently not used
  // public async setCustomerCode(code: string): Promise<APIResponse> {
  //   return await this.instance.post(`/code`, { code });
  // }
}
