import { defineStore } from "pinia";

type AlertBannerState = {
  active: boolean;
  message: string;
  variant: string;
};
export default defineStore("alertBanner", {
  state: (): AlertBannerState => ({
    active: false,
    message: "",
    variant: "",
  }),
  actions: {
    updateActive(value: boolean) {
      this.active = value;
    },
    updateMessage(value: string) {
      this.message = value;
    },
    updateVariant(value: string) {
      this.variant = value;
    },
  },
});
