import { type Plugin } from 'vue';
import CustomerCodeApi from '@/api/routes/CustomerCode';
const CustomerCode = new CustomerCodeApi();

export const API = {
  CustomerCode,
};

const apiClient: Plugin = {
  install: (app) => {
    app.config.globalProperties.$api = API;
    app.provide('api', API);
  },
};

export default apiClient;

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $api: typeof API;
  }
}

export type APIType = typeof API;

export function useAPI() {
  return API;
}
